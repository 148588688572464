const crypto = require('crypto');

const generate_key = function() {
  // return crypto.randomBytes(16).toString('base64');
  return crypto.randomBytes(16).toString('hex');
};

export const get_session_key = () => {
  let sessionKey = sessionStorage.getItem("currentSessionKey");
  if( !sessionKey ) {
    sessionKey = generate_key() as string;
    sessionStorage.setItem("currentSessionKey", sessionKey);
  } else {
    // console.log(sessionStorage);
  }
  return sessionKey;
}
