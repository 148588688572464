// 运行时配置
import RightContent from '@/components/RightContent/index';
import defaultSettings from '../config/defaultSettings';
import { useKeepOutlets } from '@umijs/max';
import { RequestConfig } from 'umi';
import './assets/css/common.less'; //公共css样式
import './assets/fonts/iconfont.css';
import './assets/fonts/iconfont.js';
import {
  TokRequestInterceptors,
  TokResponseInterceptor,
  tok_access,
} from './utils/HttpUtil';
// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate
// const user = localStorage.getItem(
//   process.env.REACT_APP_CONFIG_ENV + '_supplier_user',
// );
// export async function getInitialState(): Promise<{ name: any }> {
//   return { name: JSON.parse(user).nick };
// }

export const layout = () => {
  return {
    logo: '/logo.svg',
    iconfontUrl: '/fonts/iconfont.js',
    menu: {
      locale: false,
    },
    token: {
      sider: {
        colorMenuBackground: '#fff',
        colorBgMenuItemHover: '#FFF2F0',
        colorBgMenuItemCollapsedHover: '#FFF2F0',
        colorBgMenuItemSelected: '#FFF2F0',
        colorTextMenu: '#001529',
        colorTextMenuSelected: '#FF4D4F',
        colorTextMenuActive: '#001529',
        colorTextMenuSecondary: '#001529',
        colorTextMenuItemHover: '#FF4D4F',
      },
      header: {
        colorBgHeader: '#fff',
        colorHeaderTitle: '#001529',
      },
    },
    layout: 'mix',
    fixedHeader: false,
    fixSiderbar: true,
    locale: 'zh-CN',
    contentWidth: 'Fluid',
    // title: '步甲科技-物流管理系统',
    settings: defaultSettings,
    childrenRender: (dom, props) => {
      //渲染多标签 keep-alive
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const element = useKeepOutlets();
      return element;
    },
    rightContentRender: () => RightContent(),
  };
};
export async function getInitialState(): Promise<any> {
  let currentUser = localStorage.getItem(
    process.env.REACT_APP_CONFIG_ENV + '_supplier_user',
  );

  // tok_access({ uid: '' }).then(async (res: any) => {
  //   if (res?.ret_info?.ret === 0) {
  //     sessionStorage.setItem(
  //       process.env.REACT_APP_CONFIG_ENV + '_finance_access',
  //       JSON.stringify(res?.sys_menu),
  //     );
  //   }
  // });
console.log(!currentUser,'===!currentUser');

  if (!currentUser) {
    history.replace({
      pathname: '/user/login' + location.search,
    });
  }

  return {
    currentUser: currentUser && JSON.parse(currentUser),
  };
}
export const request: RequestConfig = {
  errorConfig: {
    // errorHandler:errorHandler,
  },
  requestInterceptors: [TokRequestInterceptors],
  responseInterceptors: [TokResponseInterceptor as any],
};
