import { get_session_key } from './StorageUtil';
// import { request, history } from 'umi';
import { request } from '@@/plugin-request';
import { RequestConfig, history } from '@umijs/max';
import { Decrypt, Encrypt } from './AESUtil';
// import { message } from "antd";
// import {
//   RequestOptionsInit,
//   RequestOptionsWithoutResponse,
//   RequestOptionsWithResponse,
//   RequestResponse,
// } from 'umi-request';

// export const BOSS_FILE_URL = process.env.REACT_APP_CONFIG_ENV === "formal" ? "http://boss.kidoroo.com/file" : "http://dev.boss.kidoroo.com/file";
// export const BOSS_API_URL = process.env.REACT_APP_CONFIG_ENV === "formal" ? 'https://bjwx.yzdbj.cn/wx' : 'https://bjwx.yzdbj.cn/wx';
// export const BOSS_API_URL = process.env.REACT_APP_CONFIG_ENV === "formal" ? 'https://api.ttookk.com/wx' : 'https://api.ttookk.com/wx';
// export const BOSS_API_URL = process.env.REACT_APP_CONFIG_ENV === "formal" ? 'https://boss.ttookk.com/wx' : 'https://boss.ttookk.com/wx';
// export const BOSS_API_URL = process.env.REACT_APP_CONFIG_ENV === "formal" ? 'http://access.kido.com/wx' : 'http://access.kido.com/wx';
// export const BOSS_API_URL = process.env.REACT_APP_CONFIG_ENV === "formal" ? 'http://taotao.51bujia.com/wx' : 'http://taotao.51bujia.com/wx';
// export const BOSS_API_URL = process.env.REACT_APP_CONFIG_ENV === "formal" ? 'https://api.test.ttookk.com/wx' : 'https://api.test.ttookk.com/wx';

// let judgement_env = 'http://access.kido.com/wx';
let judgement_env = 'https://api.test.ttookk.com/wx';
// let judgement_env = 'http://192.168.0.125/wx';
// let judgement_env = 'https://api.alpha.ttookk.com/wx';
// let judgement_env = 'https://dev175.51bujia.com/wx';
// let judgement_env = 'http://bo.51bujia.com/wx';
// let judgement_env = "https://api.prod.ttookk.com/wx";
if (process.env.REACT_APP_CONFIG_ENV === 'test') {
  judgement_env = 'https://api.test.ttookk.com/wx';
  // judgement_env = 'http://192.168.0.125/wx';
} else if (process.env.REACT_APP_CONFIG_ENV === 'dev') {
  judgement_env = 'https://api.alpha.ttookk.com/wx';
} else if (process.env.REACT_APP_CONFIG_ENV === 'prod') {
  judgement_env = 'https://api.prod.ttookk.com/wx';
}
export const BOSS_API_URL = judgement_env;

// 默认c3key
export const DEFAULT_C3KEY = 'Took-65789527-Fa';
// 默认c3iv
export const DEFAULT_C3IV = 'Took06D2D3e048D8';

// interface TokRequestMethodInUmi<R = false> {
//   <T = any>(
//     cmd: number,
//     data: any,
//     options: RequestOptionsWithResponse & { skipErrorHandler?: boolean },
//     data_filter?: (response_data: any) => any,
//     filter_params?: any,
//     _isFixKey?: boolean | false,
//   ): Promise<RequestResponse<T>>;

//   <T = any>(
//     cmd: number,
//     data: any,
//     options: RequestOptionsWithoutResponse & { skipErrorHandler?: boolean },
//     data_filter?: (response_data: any) => any,
//     filter_params?: any,
//     _isFixKey?: boolean | false,
//   ): Promise<T>;

//   <T = any>(
//     cmd: number,
//     data: any,
//     options?: RequestOptionsInit & { skipErrorHandler?: boolean },
//     data_filter?: (response_data: any) => any,
//     filter_params?: any,
//     _isFixKey?: boolean | false,
//   ): R extends true ? Promise<RequestResponse<T>> : Promise<T>;
// }

interface TokRequestConfig extends RequestConfig {
  headers: {
    method: string;
  };
  _isFixKey: boolean;
  cmd: number;
}

interface TokResponseConfig extends RequestConfig {
  config: {
    cmd?: number;
  };
}

export const tok_request = async (
  cmd: number,
  data: any,
  options: any,
  _isFixKey?: boolean | false,
) => {
  let url = BOSS_API_URL;
  const data1 = await request(url, {
    ...options,
    data,
    cmd,
    _isFixKey,
  });
  console.log(
    {
      ...options,
      data,
      cmd,
      _isFixKey,
    },
    '====tok_request',
  );

  return data1;
};

const tok_login = (body: any) =>
  tok_request(20000, body, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
  });

const tok_logined_op = (
  cmdCode: number,
  body: any,
  _isFixKey?: boolean | true,
) => {
  if (_isFixKey === undefined) {
    // eslint-disable-next-line no-param-reassign
    _isFixKey = true;
  }
  console.log(cmdCode, _isFixKey);
  let _options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
  };
  // if (options) {
  //   _options = { ..._options, ...options };
  // }

  const retdata = tok_request(
    cmdCode,
    body,
    _options,
    _isFixKey,
  ) as Promise<any>;

  // try {
  //   const excludeCMD = [80001, 80002, 80004, 80005, 80006, 50081];
  //   const flag = excludeCMD.some((item) => item === cmdCode);
  // if (!flag) {
  //   retdata.then((res: any) => {
  //     console.log(res);
  //     console.log(cmdCode, body);
  //     if (res?.ret_info?.ret !== 0) {
  //       const data = {
  //         cmd: cmdCode + '',
  //         request: JSON.stringify(body),
  //         response: JSON.stringify(res),
  //       };
  //       tok_request(50081, data, {
  //         method: 'POST',
  //         mode: 'cors',
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //           Accept: 'application/json',
  //         },
  //       });
  //     }
  //   });
  // }
  // } catch (err) {
  //   console.log(err);
  // }
  return retdata;
};

// 权限接口
const tok_access = (body: any) =>
  tok_request(
    20105,
    body,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    },
    true,
  );

//查询
export const tok_auth_select = (
  configFile: string,
  configName: string,
  param: any,
  _isFixKey?: boolean | true,
) =>
  tok_logined_op(
    80001,
    {
      configFile: configFile,
      configName: configName,
      paraMap: param,
    },
    _isFixKey,
  );

// interface PageNoQuery {
//   pageNo: number;
//   pageSize: number;
// }

// const tok_auth_select_by_page_default_filter = (
//   response_data: any,
//   filter_params?: any,
// ) => {
//   // console.log(filter_params)
//   let data = response_data.values;
//   if (filter_params?.key) {
//     data.forEach((k) => {
//       k['key'] = k[filter_params.key];
//     });
//   }
//   return { data: data, success: true, total: response_data.pageInfo.total };
// };

export const tok_auth_select_by_page = (
  configFile: string,
  configName: string,
  param: any,
  page: any,
) =>
  tok_logined_op(
    80002,
    {
      configFile: configFile,
      configName: configName,
      pageNoQuery: { pageNo: page.current, pageSize: page.pageSize },
      paraMap: param || {},
    },
    true,
  );

// {"pageInfo":{"pageNo":1,"pageSize":5,"total":12,"totalPageCount":3,"valid":true}

export const tok_auth_insert = (
  configFile: string,
  configName: string,
  param: any,
) =>
  tok_logined_op(80004, {
    configFile: configFile,
    configName: configName,
    paraMap: param,
  });

export const tok_auth_update = (
  configFile: string,
  configName: string,
  param: any,
) =>
  tok_logined_op(80005, {
    configFile: configFile,
    configName: configName,
    paraMap: param,
  });
export const tok_auth_delete = (
  configFile: string,
  configName: string,
  param: any,
) =>
  tok_logined_op(80006, {
    configFile: configFile,
    configName: configName,
    paraMap: param,
  });

const TokRequestInterceptors = (config: TokRequestConfig) => {
  console.log('====================================');
  console.log(config);
  console.log('====================================');
  const {
    headers: { method },
    _isFixKey,
    cmd,
    data,
  } = config;
  if (method === 'get') {
  }
  let _ticket: any | undefined = {
    c3Key: DEFAULT_C3KEY,
    c3Iv: DEFAULT_C3IV,
    c2: '',
    c3: '',
  };
  let _user: any = {
    id: 0,
    uid: '',
  };
  if (_isFixKey) {
    _ticket = JSON.parse(
      localStorage.getItem(
        process.env.REACT_APP_CONFIG_ENV + '_supplier_ticket',
      ) as string,
    );
    if (!_ticket || _ticket.expireTs < new Date().getTime()) {
      history.replace({
        pathname: '/user/login',
      });
      return;
    }
    _user = JSON.parse(
      localStorage.getItem(
        process.env.REACT_APP_CONFIG_ENV + '_supplier_user',
      ) as string,
    );
  }
  let requestData = {
    cmd: cmd,
    c2: _ticket.c2,
    request: JSON.stringify(data),
  };
  console.log('request', requestData);
  let cryptReqData = Encrypt(requestData, _ticket.c3Key, _ticket.c3Iv);
  console.log(_ticket);
  console.log(cryptReqData);
  config.data = {
    userId: 0,
    appId: 'supplier938476dfjc923hfsd83',
    // appIdKey: 'supplier938476dfjc923hfsd83',
    uid: _user.uid,
    // login_account: _user.name,
    seq: 1,
    isResponse: false,
    c3: _ticket.c3,
    ua: navigator.userAgent,
    sk: get_session_key(),
    uuid: 'KKUUII',
    openId: 'fafa',
    // ns: 'CA',
    // nsNo: 'gagajgaga',
    requestData: cryptReqData,
    // role: _user.access,
  };
  console.log(config);

  return { ...config };
};

const TokResponseInterceptor = (
  response: TokResponseConfig,
): Promise<TokResponseConfig> => {
  // console.log(response);
  if (response?.config?.cmd) {
    let _ticket = {
      c3Key: DEFAULT_C3KEY,
      c3Iv: DEFAULT_C3IV,
      c2: '',
      c3: '',
    };
    const data = response.data;
    console.log(data);
    if (data.code === undefined) {
      throw 'code码为空！';
    }
    if (data.code === 0) {
      let decRes;
      if (!data.isFixedC3Key) {
        _ticket = JSON.parse(
          localStorage.getItem(
            process.env.REACT_APP_CONFIG_ENV + '_supplier_ticket',
          ) as string,
        );
        console.log(_ticket);
        decRes = Decrypt(data.responseData, _ticket.c3Key, _ticket.c3Iv);
      } else {
        decRes = Decrypt(data.responseData, DEFAULT_C3KEY, DEFAULT_C3IV);
      }
      let resObj = JSON.parse(decRes);
      console.log(resObj);
      // let cmd = resObj.cmd;
      resObj = JSON.parse(resObj.response);
      if (typeof resObj === 'string') {
        resObj = JSON.parse(resObj);
      }
      console.info('------------------------------------2');
      console.info(resObj);
      console.info('------------------------------------2');
      response.data = resObj;
      return Promise.resolve(response);
    } else if (data.code === 10 || data.code === 200) {
      localStorage.removeItem(
        process.env.REACT_APP_CONFIG_ENV + '_supplier_ticket',
      );
      // throw "票据无效,请重新登录！";
      history.replace({
        pathname: '/user/login',
      });
    } else if (data.code === -10) {
      localStorage.removeItem(
        process.env.REACT_APP_CONFIG_ENV + '_supplier_ticket',
      );
      // throw "票据无效,请重新登录！";
      history.replace({
        pathname: '/user/login',
      });
    } else if (data.code === -999) {
      throw '无权限操作！';
      // return false;
    } else {
      throw '服务器异常，code无效';
    }
  }
  return Promise.reject(response);
};

export {
  TokRequestInterceptors,
  TokResponseInterceptor,
  tok_access,
  tok_login,
  tok_logined_op,
};
