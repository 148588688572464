import * as CryptoJS from 'crypto-js';

/* AES加密 */
export function Encrypt(data: any, c3Key: string, c3Iv: string) {
  const dataStr = JSON.stringify(data);
  // console.log(dataStr);
  const encrypted = CryptoJS.AES.encrypt(
    dataStr,
    CryptoJS.enc.Latin1.parse(c3Key),
    {
      iv: CryptoJS.enc.Latin1.parse(c3Iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return encrypted.toString();
}

/* AES解密 */
export function Decrypt(data: any, c3Key: string, c3Iv: string) {
  let data2 = data.replace(/\n/gm, '');
  const decrypted = CryptoJS.AES.decrypt(
    data2,
    CryptoJS.enc.Latin1.parse(c3Key),
    {
      iv: CryptoJS.enc.Latin1.parse(c3Iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}
