import { tok_login } from '@/utils/HttpUtil';
import { message } from 'antd';
import { stringify } from 'querystring';
import { history, Effect, Subscription,Reducer } from 'umi';

export interface UserModelState {
  id: number | string;
  name: string;
  tokUser: any;
  isLogin: boolean;
}

export interface DispatchProps {
  login?: () => void;
  logout?: () => void;
}

export interface UserModelType {
  namespace: string;
  state: UserModelState;
  effects: {
    login: Effect;
    logout: Effect;
  };
  reducers: {
    // 启用 immer
    save: Reducer<UserModelState>;
  };
  subscriptions: { setup: Subscription };
}

const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

/**
 * 此方法会跳转到 redirect 参数所在的位置
 */
const goto = () => {
  const { query } = history.location;
  const { redirect } = query as { redirect: string };
  // redirect ||
  window.location.href = '/';
};

export default {
  namespace: 'userInfo',
  state: {
    isLogin: false,
    userInfo: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *login({ payload }, { call, put }) {
      console.log(payload);
      const res = yield call(tok_login, payload);
      console.log(res);
      const data = {
        userInfo: {},
        isLogin: false,
      };
      if (res.loginResult === 0) {
        localStorage.setItem(
          process.env.REACT_APP_CONFIG_ENV + '_supplier_ticket',
          JSON.stringify({
            appId: res.webTicket.appId,
            c2: res.webTicket.c2,
            c3: res.webTicket.c3,
            c3Key: res.webTicket.c3Key,
            c3Iv: res.webTicket.c3Iv,
            expireTs: res.webTicket.expireTs,
          }),
        );
        localStorage.setItem(
          process.env.REACT_APP_CONFIG_ENV + '_supplier_user',
          JSON.stringify({
            headImgUrl: res.tokUser.headImgUrl,
            uid: res.tokUser.uid,
            nick: res.tokUser.userNick,
            access: res.tokUser.is_broker,
            userMobile: res.tokUser.userMobile,
            is_enterprise: res.tokUser.is_enterprise,
            is_multi_store: res.tokUser.is_multi_store,
            is_salesman: res.tokUser.is_salesman,
            organization_type: res.tokUser.organization_type,
            is_need_auto_bid: res.tokUser.is_need_auto_bid,
          }),
        );
        console.log(res.tokUser, '=========data.tokUser');
        data.userInfo = res.tokUser;
        window.location.href = '/home';
        waitTime(3000);
        message.success('登录成功！');
        goto();
      } else {
        message.error('账号或密码错误，请重试！');
      }
      yield put({ type: 'save', payload: data });
    },
    *logout(_, { call, put }) {
      localStorage.removeItem(process.env.REACT_APP_CONFIG_ENV + '_supplier_user');
      localStorage.removeItem('status');
      const { query, pathname } = history.location;
      const { redirect } = query;
      // Note: There may be security issues, please note
      if (window.location.pathname !== '/user/login' && !redirect) {
        history.replace({
          pathname: '/user/login',
          search: stringify({
            redirect: pathname,
          }),
        });
      }
      const payload = {
        userInfo: {},
        isLogin: false,
      };
      yield put({ type: 'save', payload });
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      const user = JSON.parse(
        localStorage.getItem(process.env.REACT_APP_CONFIG_ENV + '_supplier_user'),
      );
      const payload = {
        userInfo: {},
        isLogin: false,
      };
      if (user) {
        payload.userInfo = user;
        payload.isLogin = true;
      } else {
        payload.userInfo = {};
        payload.isLogin = false;
      }
      dispatch({ type: 'save', payload });
    },
  },
};
