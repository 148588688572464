import { Settings as LayoutSettings } from '@ant-design/pro-layout';

let primaryColor = '#FF9900';
if (process.env.REACT_APP_CONFIG_ENV === 'test') {
  primaryColor = 'red';
} else if (process.env.REACT_APP_CONFIG_ENV === 'dev') {
  primaryColor = 'green';
} else if (process.env.REACT_APP_CONFIG_ENV === 'prod') {
  primaryColor = '#1890ff';
}

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'light',
  // 拂晓蓝
  // primaryColor: '#1890ff',
  primaryColor: primaryColor,
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  // title: '步甲科技-物流管理系统',
  pwa: false,
  logo: '/logo.svg',
  iconfontUrl: '//at.alicdn.com/t/font_2673781_l4vr5gb1d9n.js',
};

export default Settings;
