// import { Tooltip, Tag, Space } from 'antd';
// import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import Avatar from './AvatarDropdown';
// import NoticeIcon from '../NoticeIcon';
// import HeaderSearch from '../HeaderSearch';

export type SiderTheme = 'light' | 'dark';

export default () => {
  return (
    <Avatar />
    // <div>
    //   <NoticeIcon style={{ marginRight: '10px' }} />
    //   <Avatar />
    // </div>
  );
};
